/**
 * Converts a JavaScript object to a URI encoded query string.
 * @param {any} obj The parameters for the query string.
 */
export function createQueryString(obj) {
    if (!obj || obj.length === 0) {
        return null;
    }
    var params = [];
    for (var property in obj) {
        if (!obj.hasOwnProperty(property)) {
            continue;
        }
        if (obj[property] instanceof Array) {
            for (var index in obj[property]) {
                if (obj[property].hasOwnProperty(index)) {
                    params.push(encodeURIComponent(property) + '=' + (obj[property][index] !== null && typeof obj[property][index] !== 'undefined' ? encodeURIComponent(obj[property][index]) : ''));
                }
            }
        }
        else {
            params.push(encodeURIComponent(property) + '=' + (obj[property] !== null && typeof obj[property] !== 'undefined' ? encodeURIComponent(obj[property]) : ''));
        }
    }
    return '?' + params.join('&');
}
