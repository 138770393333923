import * as tslib_1 from "tslib";
var FoundationPropertyOptions = /** @class */ (function () {
    function FoundationPropertyOptions(options) {
        this.get = true;
        this.set = true;
        if (options) {
            Object.assign(this, options);
        }
    }
    return FoundationPropertyOptions;
}());
var foundationPropertyNotFoundMessage = function (className, propertyName) { return className + "'s foundation does not contain the property \"" + propertyName + "\""; };
var foundationNotFoundMessage = function (className) { return className + " does not have a foundation"; };
function runIfVerified(target, propertyName, action) {
    if (target._foundation) {
        if (propertyName in target._foundation) {
            return action();
        }
        else {
            throw new Error(foundationPropertyNotFoundMessage(target.localName, propertyName));
        }
    }
    else {
        throw new Error(foundationNotFoundMessage(target.localName));
    }
}
export function FoundationProperty(options) {
    var allOptions = new FoundationPropertyOptions(options);
    return function (target, name, descriptor) {
        var defaultGet;
        var defaultSet;
        var propertyName = name;
        var foundationPropertyName = ((options && options.name) || name).toString();
        if (descriptor) {
            defaultGet = descriptor.get;
            defaultSet = descriptor.set;
            descriptor.configurable = true;
            descriptor.enumerable = true;
            if (allOptions.set) {
                descriptor.set = function (value) {
                    return wireDescriptorSet(this, foundationPropertyName, function (attributes) {
                        var desc = Object.getOwnPropertyDescriptor(target, foundationPropertyName);
                        desc.set = attributes.set;
                        Reflect.defineProperty(target, propertyName, desc);
                        attributes.set(value);
                    }, defaultSet);
                };
            }
            if (allOptions.get) {
                descriptor.get = function () {
                    return wireDescriptorGet(this, foundationPropertyName, function (attributes) {
                        var desc = Object.getOwnPropertyDescriptor(target, foundationPropertyName);
                        desc.get = attributes.get;
                        Reflect.defineProperty(target, propertyName, desc);
                        return attributes.get();
                    }, defaultGet);
                };
            }
        }
        else {
            if (allOptions.set || allOptions.get) {
                var attributes = { configurable: true, enumerable: true };
                var get_1 = {
                    get: function () {
                        var that = this;
                        return wireDescriptorGet(that, foundationPropertyName, function (attrs) {
                            var setter;
                            // We have to wire the setter here as well
                            if (allOptions.set) {
                                setter = tslib_1.__assign({}, set_1);
                            }
                            Reflect.defineProperty(that, foundationPropertyName, tslib_1.__assign({ configurable: true, enumerable: true }, attrs, setter));
                            return attrs.get();
                        });
                    }
                };
                var set_1 = {
                    set: function (value) {
                        var that = this;
                        return wireDescriptorSet(that, foundationPropertyName, function (attrs) {
                            var getter;
                            // We have to wire the getter here as well
                            if (allOptions.get) {
                                getter = tslib_1.__assign({}, get_1);
                            }
                            Reflect.defineProperty(that, foundationPropertyName, tslib_1.__assign({ configurable: true, enumerable: true }, attrs, getter));
                            attrs.set(value);
                        });
                    }
                };
                if (allOptions.get) {
                    Object.assign(attributes, tslib_1.__assign({}, get_1));
                }
                if (allOptions.set) {
                    Object.assign(attributes, tslib_1.__assign({}, set_1));
                }
                Reflect.defineProperty(target, propertyName, attributes);
            }
        }
    };
}
function setFoundation(target, value, propertyName) {
    target._foundation[propertyName] = value;
}
function getFoundation(target, propertyName) {
    return target._foundation[propertyName];
}
function wireDescriptorSet(target, propertyName, wireAction, defaultSet) {
    var attributes;
    if (defaultSet) {
        attributes = {
            set: function (value) {
                defaultSet.call(target, value);
                setFoundation(target, value, propertyName);
            }
        };
    }
    else {
        attributes = {
            set: function (value) {
                setFoundation(target, value, propertyName);
            }
        };
    }
    return runIfVerified(target, propertyName, function () { return wireAction(attributes); });
}
function wireDescriptorGet(target, propertyName, wireAction, defaultGet) {
    var attributes;
    if (defaultGet) {
        attributes = {
            get: function () {
                defaultGet.call(target);
                return getFoundation(target, propertyName);
            }
        };
    }
    else {
        attributes = {
            get: function () {
                return getFoundation(target, propertyName);
            }
        };
    }
    return runIfVerified(target, propertyName, function () { return wireAction(attributes); });
}
