import { findWhere, createPredicate } from './object-utils';
var ItemManager = /** @class */ (function () {
    function ItemManager(_key) {
        this._key = _key;
        this._items = [];
    }
    /**
     * Returns all items.
     */
    ItemManager.prototype.getItems = function () {
        return this._items.slice();
    };
    /**
     * Returns the number of items.
     */
    ItemManager.prototype.count = function () {
        return this._items.length;
    };
    /**
     * Sets the item key.
     * @param key The item key(s).
     */
    ItemManager.prototype.setKey = function (key) {
        this._key = key;
    };
    /**
     * Addes the provided items.
     * @param data The item data.
     */
    ItemManager.prototype.add = function (data) {
        var _this = this;
        if (!(data instanceof Array)) {
            data = [data];
        }
        data.forEach(function (item) {
            if (!_this.exists(item)) {
                _this._items.push(item);
            }
        });
        return this;
    };
    /**
     * Removes an item from the selections.
     * @param data The data to be deselected.
     */
    ItemManager.prototype.remove = function (data) {
        if (!(data instanceof Array)) {
            data = [data];
        }
        for (var i = data.length - 1; i >= 0; i--) {
            if (this.exists(data[i])) {
                this._items.splice(this._items.indexOf(this._getItem(data[i])), 1);
            }
        }
        return this;
    };
    /**
     * Removes all selected items.
     */
    ItemManager.prototype.clear = function () {
        this._items = [];
        return this;
    };
    /**
     * Determines if the provided item exists in the selections or not.
     * @param data The data value.
     */
    ItemManager.prototype.exists = function (data) {
        return this._getItem(data) !== null;
    };
    /**
     * Gets the item from the items collection, or null if not found.
     * @param data The data value.
     */
    ItemManager.prototype._getItem = function (data) {
        if (this._key) {
            return findWhere(this._items, createPredicate(this._key, data)) || null;
        }
        else {
            return this._items.find(function (item) { return item === data; }) || null;
        }
    };
    return ItemManager;
}());
export { ItemManager };
