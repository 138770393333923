import { replaceElement, isArray, removeAllChildren, walkUpUntil } from '../utils';
/**
 * Recursively defines a component as a custom elements and all of its dependencies.
 * @param component The component to import.
 */
export function defineCustomElement(component) {
    tryDefine(component['_customElementName'], component);
    if (isArray(component['_customElementDependencies'])) {
        defineCustomElements(component['_customElementDependencies']);
    }
}
/**
 * Defines the specified custom element components.
 * @param {any[]} components The components to register.
 */
export function defineCustomElements(components) {
    components.forEach(defineCustomElement);
}
/**
 * Attempts to define the provided custom element name/constructor if not already defined.
 * @param name The name of the custom element to define.
 * @param ctor The custom element constructor.
 */
// tslint:disable-next-line:ban-types
export function tryDefine(name, ctor, options) {
    if (window.customElements.get(name)) {
        return;
    }
    window.customElements.define(name, ctor, options);
}
/**
 * Useful when capturing the value of a unupgraded component during the `connectedCallback` upon upgrade.
 *
 * More information here:
 * https://developers.google.com/web/fundamentals/architecture/building-components/best-practices#lazy-properties
 *
 * @param property
 */
export function upgradeProperty(instance, property) {
    if (instance.hasOwnProperty(property)) {
        var value = instance[property];
        delete instance[property];
        instance[property] = value;
    }
}
/**
 * Traverses up the DOM tree starting from the provided component element to find the specified parent.
 * @param {HTMLElement} component The starting HTMLElement.
 * @param {string} parentTagName The parent tag name we are searching for.
 */
export function requireParent(component, parentTagName) {
    var el = component;
    while (el.parentNode) {
        el = el.parentNode;
        if (!el.tagName) {
            break;
        }
        if (!el.tagName || el.tagName.toLowerCase() === parentTagName.toLowerCase()) {
            return el;
        }
    }
    return null;
}
/**
 * Creates a template element from a string.
 * @param template The template HTML string.
 */
export function parseTemplateString(template) {
    var templateDocument = new DOMParser().parseFromString(template, 'text/html');
    return createTemplateContent(templateDocument.querySelector('template'));
}
/**
 * Attaches a template to the given web component instance light DOM.
 * @param {T} componentInstance A component instance.
 * @param {string} template The template HTML string.
 */
export function attachLightTemplate(componentInstance, template) {
    componentInstance.appendChild(parseTemplateString(template).content.cloneNode(true));
}
/**
 * Attaches a shadow root to the given web component instance.
 * @param {T} componentInstance A component instance.
 * @param {string} elementName The name of the element the shadow root is to be attached to.
 * @param {string} template The shadow root template HTML string.
 * @param {string | string[]} styles The shadow root styles string to be encapsulated by this shadow root.
 * @param {boolean} [delegatesFocus=false] Should the component delagate focus.
 */
export function attachShadowTemplate(componentInstance, elementName, template, styles, delegatesFocus) {
    if (delegatesFocus === void 0) { delegatesFocus = false; }
    var templateElement = prepareShadowTemplate(elementName, template, styles);
    componentInstance.attachShadow({ mode: 'open', delegatesFocus: delegatesFocus });
    setShadowTemplate(componentInstance, templateElement);
}
/**
 * Replaces the template of an existing shadow root with the provided template.
 * @param {T} componentInstance A component instance.
 * @param {string} elementName The name of the element the shadow root is to be attached to.
 * @param {string} template The shadow root template HTML string.
 * @param {string | string[]} styles The shadow root styles string to be encapsulated by this shadow root.
 */
export function replaceShadowTemplate(componentInstance, elementName, template, styles) {
    if (!componentInstance.shadowRoot) {
        throw new Error('This element does not contain a shadow root. Did you mean to call `attachShadowTemplate`?');
    }
    var templateElement = prepareShadowTemplate(elementName, template, styles);
    if (componentInstance.shadowRoot.children.length) {
        removeAllChildren(componentInstance.shadowRoot);
    }
    setShadowTemplate(componentInstance, templateElement);
}
/**
 * Creates and prepares an HTML template element for rendering within a shadow root.
 * @param {string} elementName The name of the element the shadow root is to be attached to.
 * @param {string} template The shadow root template HTML string.
 * @param {string | string[]} styles The shadow root styles string to be encapsulated by this shadow root.
 */
export function prepareShadowTemplate(elementName, template, styles) {
    var templateElement = parseTemplateString(template);
    if (styles) {
        styles = styles instanceof Array ? styles : [styles];
        var styleElement = document.createElement('style');
        styleElement.type = 'text/css';
        styleElement.textContent = styles.join(' ');
        templateElement.content.appendChild(styleElement);
    }
    if (window.ShadyCSS && !window.ShadyCSS.nativeShadow) {
        window.ShadyCSS.prepareTemplate(templateElement, elementName);
    }
    return templateElement;
}
/**
 * Appends a template to the provided components shadow root.
 * @param {T} componentInstance A component instance.
 * @param {HTMLTemplateElement} templateElement A template element to be cloned.
 */
export function setShadowTemplate(componentInstance, templateElement) {
    componentInstance.shadowRoot.appendChild(templateElement.content.cloneNode(true));
}
/**
 * Polyfills the shadow DOM styles if necessary.
 * @param componentInstance A custom element instance.
 */
export function polyfillShadowStyles(componentInstance) {
    if (window.ShadyCSS && !window.ShadyCSS.nativeShadow) {
        window.ShadyCSS.styleElement(componentInstance);
    }
}
/**
 * Polyfills the `content` property on a `<template>` element that is created dynamically.
 * @param {HTMLTemplateElement} template The template element.
 */
export function createTemplateContent(template) {
    if (template.content) {
        return template;
    }
    template.content = template.ownerDocument.createDocumentFragment();
    var child;
    while (child = template.firstChild) { // tslint:disable-line
        template.content.appendChild(child);
    }
    return template;
}
/**
 * Copies style rules from the provided document stylesheets collection to the provided shadow root stylesheet.
 * @param {Document} fromDocument The document to find the style sheets in.
 * @param {ShadowRoot} shadowRoot The shadow root that contains the stylesheet to copy the rules to.
 * @param {IStyleSheetDescriptor[]} styleSheetDescriptors A collection of style sheet predicates.
 * @param {CSSStyleSheet} shadowStyleSheet The shadow root stylesheet to copy the style rules to.
 */
export function provideDocumentStyles(fromDocument, shadowRoot, documentStyleSheets, shadowStyleSheet) {
    if (!shadowStyleSheet) {
        return;
    }
    var documentSheets = [];
    documentStyleSheets.forEach(function (sheet) {
        var sheetName = typeof sheet === 'string' ? sheet : sheet.name;
        var sheetFilter = sheet.selectorFilter;
        var matchingStyleSheet = _findMatchingStyleSheet(fromDocument.styleSheets, sheetName);
        if (!matchingStyleSheet) {
            throw new Error("Could not find stylesheet: " + sheetName);
        }
        var startIndex = shadowStyleSheet.cssRules.length;
        for (var rule in matchingStyleSheet.cssRules) {
            if (matchingStyleSheet.cssRules.hasOwnProperty(rule) && matchingStyleSheet.cssRules[rule].cssText && (!sheetFilter || new RegExp(sheetFilter).test(matchingStyleSheet.cssRules[rule].selectorText))) {
                shadowStyleSheet.insertRule(matchingStyleSheet.cssRules[rule].cssText, startIndex++);
            }
        }
    });
}
/**
 * Finds a stylesheet by name in the provided stylesheet list.
 * @param styleSheetList The stylesheet list to search.
 * @param sheetName The stylesheet name to find.
 * @returns {CSSStyleSheet | undefined}
 */
function _findMatchingStyleSheet(styleSheetList, sheetName) {
    for (var prop in styleSheetList) {
        if (styleSheetList.hasOwnProperty(prop) && styleSheetList[prop].href) {
            if (new RegExp(sheetName).test(styleSheetList[prop].href)) {
                return styleSheetList[prop];
            }
        }
    }
    return undefined;
}
/**
 * Gets an HTML element using a query selector from the provided components` shadow root.
 * @param {HTMLElement} componentInstance The component instance that contains a shadow root.
 * @param {string} selector The selector to be passed to `querySelector`.
 */
export function getShadowElement(componentInstance, selector) {
    return componentInstance.shadowRoot.querySelector(selector);
}
/**
 * Gets an HTML element using a query selector from the provided components` light DOM.
 * @param {HTMLElement} componentInstance The component instance.
 * @param {string} selector The selector to be passed to `querySelector`.
 */
export function getLightElement(componentInstance, selector) {
    return componentInstance.querySelector(selector);
}
/**
 * Creates and dispatches a cross-browser `CustomEvent` with the provided type and data.
 * @param {string} type
 * @param {any} data
 * @param {boolean=} bubble
 */
export function emitEvent(component, type, data, bubble, cancelable) {
    if (bubble === void 0) { bubble = true; }
    if (cancelable === void 0) { cancelable = false; }
    var evt;
    if (typeof CustomEvent === 'function') {
        evt = new CustomEvent(type, {
            detail: data,
            bubbles: bubble,
            cancelable: cancelable
        });
    }
    else {
        evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(type, bubble, cancelable, data);
    }
    return component.dispatchEvent(evt);
}
/**
 * Replaces the provided element with a placeholder comment and vice versa.
 * Useful for hiding and showing elements while retaining their location in the DOM.
 * @param {boolean} isVisible Whether the element is visible or not.
 * @param {string} elementName The element tag name.
 * @param {string} selector The selector used to find the element
 * @param {Node} element The element
 * @param {Comment} placeholder The existing placeholder
 */
export function toggleElementPlaceholder(component, isVisible, elementName, selector, element, placeholder) {
    var exists = !!getShadowElement(component, selector);
    if (!placeholder) {
        placeholder = document.createComment("(" + elementName + ") " + selector);
    }
    if (isVisible && !exists) {
        replaceElement(element, placeholder);
    }
    else if (!isVisible && exists) {
        replaceElement(placeholder, element);
    }
    return placeholder;
}
/**
 * Walks up the tree starting a specific node and stops when it finds a shadow root.
 * @param {Node} node The node to start searching from.
 * @returns {ShadowRoot | null} The closest shadow root ancestor, or null if not inside a shadow root.
 */
export function getClosestShadowRoot(node) {
    return walkUpUntil(node, function (current) { return current.toString() === '[object ShadowRoot]'; });
}
/**
 * Finds the closest element up the tree from a starting element across shadow boundaries.
 * @param selector The CSS selector for the element to find.
 * @param startElement The element to start finding from.
 */
export function closestElement(selector, startElement) {
    function __closestFrom(el) {
        if (!el || el === document || el === window) {
            return null;
        }
        if (el.assignedSlot) {
            el = el.assignedSlot;
        }
        var found = el.closest(selector);
        return found || __closestFrom(el.getRootNode().host);
    }
    return __closestFrom(startElement);
}
// export interface IFocusTrap {
//   activate: () => IFocusTrap;
//   deactivate: (options?: IFocusTrapDeactivateOptions) => IFocusTrap;
//   pause: () => IFocusTrap;
//   unpause: () => IFocusTrap;
// }
// export interface IFocusTrapDeactivateOptions {
//   returnFocus?: boolean;
//   onDeactivate?: () => void;
// }
// export interface IFocusTrapOptions {
//   onActivate?: () => void;
//   onDeactivate?: () => void;
//   initialFocus?: Element | string | (() => void);
//   fallbackFocus?: Element | string | (() => void);
//   escapeDeactivates?: boolean;
//   clickOutsideDeactivates?: boolean;
//   returnFocusOnDeactivate?: boolean;
// }
