/**
 * Gets the node path from where the event originated from.
 * @param evt The event.
 */
export function getEventPath(evt) {
    return evt.path || (evt.composedPath && evt.composedPath()) || composedPath(evt.target);
}
/**
 * Equivalent to path/composedPath.
 * Note: Slots and shadow roots are detected, but aren't needed as they are virtually invisible anyway...
 */
export function composedPath(el) {
    var path = [];
    while (el) {
        if (el.shadowRoot) {
            if (el.shadowRoot.activeElement) {
                path.push(el.shadowRoot.activeElement);
            }
            path.push(el.shadowRoot);
        }
        path.push(el);
        if (el.tagName === 'HTML') {
            path.push(document);
            path.push(window);
            break;
        }
        el = el.parentElement;
    }
    return path;
}
