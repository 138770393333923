import * as tslib_1 from "tslib";
import { EventAware } from '../events/event-aware';
import { ScrollEvents, ScrollDirection } from './scroll-types';
import { isDefined, throttle, isNumber } from '../utils';
var DEFAULT_SCROLL_THROTTLE = 100;
/**
 * Provides facilties for observing and reacting to scroll events and information on a given element.
 */
var ScrollAxisObserver = /** @class */ (function (_super) {
    tslib_1.__extends(ScrollAxisObserver, _super);
    function ScrollAxisObserver(_element, _config) {
        if (_config === void 0) { _config = {}; }
        var _this = _super.call(this) || this;
        _this._element = _element;
        _this._config = _config;
        _this._axis = 'vertical';
        _this._scrollThreshold = 0;
        _this._lastScrollPosition = 0;
        _this._lastScrollTop = 0;
        _this._lastScrollLeft = 0;
        _this._isListening = false;
        _this._initialize();
        return _this;
    }
    ScrollAxisObserver.prototype.destroy = function () {
        this.stop();
    };
    ScrollAxisObserver.prototype.start = function () {
        if (!this._isListening) {
            this._element.addEventListener('scroll', this._scrollListener);
            this._isListening = true;
        }
    };
    ScrollAxisObserver.prototype.stop = function () {
        this._element.removeEventListener('scroll', this._scrollListener);
        this._isListening = false;
    };
    ScrollAxisObserver.prototype._initialize = function () {
        var _this = this;
        if (this._config.throttle) {
            var wait = this._config.throttleTime || DEFAULT_SCROLL_THROTTLE;
            this._scrollListener = throttle(function () { return _this._onScroll(); }, wait);
        }
        else {
            this._scrollListener = function () { return _this._onScroll(); };
        }
        if (!isDefined(this._config.paused) || !this._config.paused) {
            this.start();
        }
        if (isDefined(this._config.axis)) {
            this._axis = this._config.axis;
        }
        if (isDefined(this._config.scrollThreshold) && isNumber(this._config.scrollThreshold)) {
            this._scrollThreshold = this._config.scrollThreshold;
        }
    };
    ScrollAxisObserver.prototype._isScrollAxis = function () {
        var isVertical = this._lastScrollTop !== this._element.scrollTop;
        var isHorizontal = this._lastScrollLeft !== this._element.scrollLeft;
        return (this._axis === 'vertical' && isVertical) || (this._axis === 'horizontal' && isHorizontal);
    };
    ScrollAxisObserver.prototype._onScroll = function () {
        if (!this._isScrollAxis()) {
            return;
        }
        var position = this.scrollPosition;
        var direction = this._lastScrollPosition - position < 0 ? (this._axis === 'vertical' ? ScrollDirection.Down : ScrollDirection.Right) : (this._axis === 'vertical' ? ScrollDirection.Up : ScrollDirection.Left);
        this._emit(ScrollEvents.Scroll, { direction: direction, position: position });
        if (this._lastScrollPosition <= this._scrollThreshold && position > this._scrollThreshold) {
            this._emit(ScrollEvents.Scrolled, true);
        }
        else if (this._lastScrollPosition >= this._scrollThreshold && (this._scrollThreshold > 0 ? position < this._scrollThreshold : position === 0)) {
            this._emit(ScrollEvents.Scrolled, false);
        }
        var scrollStart = Math.round(position - this._scrollThreshold);
        var scrollEnd = Math.round(this.scrollSize - position);
        if (this._lastScrollPosition >= this._scrollThreshold && scrollStart <= 0) {
            this._emit(ScrollEvents.ScrolledStart);
        }
        else if (this._lastScrollPosition <= (this.scrollSize - this._scrollThreshold) && scrollEnd <= this._scrollThreshold) {
            this._emit(ScrollEvents.ScrolledEnd);
        }
        this._lastScrollPosition = position || 0;
        this._lastScrollTop = this._element.scrollTop || 0;
        this._lastScrollLeft = this._element.scrollLeft || 0;
    };
    Object.defineProperty(ScrollAxisObserver.prototype, "scrollPosition", {
        get: function () {
            return this._axis === 'vertical' ? this._element.scrollTop : this._element.scrollLeft;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScrollAxisObserver.prototype, "isScrolled", {
        get: function () {
            return this.scrollPosition > this._scrollThreshold;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScrollAxisObserver.prototype, "isScrolledStart", {
        get: function () {
            return this.scrollPosition === 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScrollAxisObserver.prototype, "isScrolledEnd", {
        get: function () {
            return this.scrollPosition === this._element.scrollWidth;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScrollAxisObserver.prototype, "isScrollable", {
        get: function () {
            if (this._axis === 'vertical') {
                return this._element.scrollHeight > this._element.clientHeight;
            }
            return this._element.scrollWidth > this._element.clientWidth;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScrollAxisObserver.prototype, "scrollSize", {
        get: function () {
            return (this._axis === 'vertical' ? this._element.scrollHeight : this._element.scrollWidth) - this.elementSize;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScrollAxisObserver.prototype, "elementSize", {
        get: function () {
            return this._axis === 'vertical' ? this._element.clientHeight : this._element.clientWidth;
        },
        enumerable: true,
        configurable: true
    });
    ScrollAxisObserver.prototype.setScrollPosition = function (position) {
        if (this._axis === 'vertical') {
            this._element.scrollTop = position;
        }
        else {
            this._element.scrollLeft = position;
        }
    };
    return ScrollAxisObserver;
}(EventAware));
export { ScrollAxisObserver };
