import { isFunction } from '../../utils';
import { polyfillShadowStyles } from '../component-utils';
export var CUSTOM_ELEMENT_NAME_PROPERTY = '_customElementName';
export var CUSTOM_ELEMENT_DEPENDENCIES_PROPERTY = '_customElementDependencies';
/**
 * This decorator is intended to be used on classes that extend `HTMLElement` to
 * extend/modify the behavior of a custom element.
 * @param {ICustomElementConfig} [config={}] The custom element configuration.
 */
export function CustomElement(config) {
    return function (ctor) {
        patchConnectedCallback(ctor);
        if (config.name) {
            ctor[CUSTOM_ELEMENT_NAME_PROPERTY] = config.name;
        }
        if (config.dependencies && config.dependencies.length) {
            ctor[CUSTOM_ELEMENT_DEPENDENCIES_PROPERTY] = config.dependencies;
        }
    };
}
function patchConnectedCallback(ctor) {
    var originalConnectedCallback = ctor.prototype.connectedCallback;
    ctor.prototype.connectedCallback = function () {
        if (!this.isConnected) {
            return;
        }
        polyfillShadowStyles(this);
        if (!this._isInitialized) {
            this._isInitialized = true;
            if (isFunction(this.initializedCallback)) {
                this.initializedCallback.apply(this);
            }
        }
        if (isFunction(originalConnectedCallback)) {
            originalConnectedCallback.apply(this);
        }
    };
}
